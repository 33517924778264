import { PUBLIC_APP_ENV } from "$env/static/public";
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://a566a50248b59c3ffdf726cf1932d152@o4505166173437952.ingest.us.sentry.io/4507221369552896',
  tracesSampleRate: PUBLIC_APP_ENV === 'production' ? 0.2 : 1.0,
  environment: PUBLIC_APP_ENV || 'development',

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],
  enabled: PUBLIC_APP_ENV !== 'development',
});
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
